<template>
  <b-overlay
    :show="loadingRole"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <div class="">
      <!-- <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Chọn nhân viên"
          label-for="created-by"
        >
          <v-select
            id="created-by-select"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="createdByOptions"
            label="firstName"
            searchable
            :clearable="false"
            :filterable="false"
            :loading="isLoading"
            :placeholder="$t('placeholderSelect')"
            :reduce="val => val"
            @open="handleOpenCreatedBy"
            @search="handleSearchCreatedBy"
          >
            <template #selected-option="{firstName, lastName, type}">
              <div class="w-100 d-flex justify-content-between">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ lastName }} {{ firstName }}
                </span>

                <b-badge
                  class="line-height-condensed mr-1"
                  :variant="resolveEmployeeTypeVariant(type)"
                >
                  {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
                </b-badge>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template v-slot:option="{firstName, lastName, type}">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ lastName }} {{ firstName }}
                  </span>
                </b-col>

                <b-col
                  cols="12"
                  md="3"
                >
                  <b-badge
                    :variant="resolveEmployeeTypeVariant(type)"
                  >
                    <span>
                      {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
                    </span>
                  </b-badge>
                </b-col>
              </b-row>
            </template>
          </v-select>
        </b-form-group>
      </b-col> -->
      <b-row class="d-flex-center mb-1">
        <b-col>
          <b-form-input
            v-model="searchTextFilter"
            class="mr-1"
            type="search"
            :placeholder="'Name text...'"
            :value="searchTextFilter"
            :reduce="(val) => val.value"
            maxlength="255"
            debounce="500"
            trim
            @input="(val) => $emit('update:searchTextFilter', val)"
          />
        </b-col>
        <b-col>
          <b-button
            variant="info"
            class="px-md-75 w-md"
            @click="() => showModal()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="PlusSquareIcon"
                size="16"
              />
              Role
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-tabs
        lazy
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        active-nav-item-class="bg-light-info border-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <b-tab
          v-for="(role, index) in dataSearchText.length > 0
            ? dataSearchText
            : dataTable"
          :key="index"
          :active="index === roleActive"
          @click="
            fetchDataClientById(
              dataClients && dataClients.length > 0
                ? dataClients[0].clientId
                : '',
            ),
            (roleActive = index)
          "
        >
          <template #title>
            <div class="d-flex-center justify-content-between w-100">
              <div>
                <feather-icon
                  icon="CircleIcon"
                  size="12"
                  class="mr-50"
                />
                <span class="font-weight-bold mr-1">{{ role.name }} </span>
              </div>
              <b-button
                v-show="index === roleActive"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-danger text-danger"
                class="p-25"
                @click="confirmDelete(role.name)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                  class="m-0 p-0"
                />
              </b-button>
            </div>
          </template>
          <b-card class="border">
            <b-tabs
              content-class="pt-1"
              fill
            >
              <b-tab
                v-for="(client, indexClient) in dataClients"
                :key="indexClient"
                lazy
                :title="client.clientId"
                @click="
                  fetchDataClientById(client.clientId),
                  (loading = true),
                  getPermissionInRole(client.id, role.name)
                "
              >
                <b-overlay
                  :show="loading"
                  rounded="sm"
                  no-fade
                  variant="transparent"
                  :opacity="0.5"
                >
                  <RoleDetail
                    :all-resources="dataResource"
                    :data-role="role"
                    :data-permission="dataPermission"
                    :client-id="clientId"
                    @setPermission="setPermissionInRole($event, role)"
                  />
                </b-overlay>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-tab>
      </b-tabs>
      <b-modal
        id="my-modal"
        hide-footer
        title="Add Role"
        centered
      >
        <CreateRole
          @hidden="hideModal"
          @insertRole="insertRole"
        />
      </b-modal>
      <!-- Table Container Card -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BFormInput,
  BInputGroup,
  BOverlay,
  BTabs,
  BTab,
  BModal,
  BRow,
  BCol,
  // BFormGroup,
  // BBadge,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import debounce from 'lodash/debounce'

import store from '@/store'
import api from '@/api'
import { resolveEmployeeTypeVariant, resolveEmployeeType, sizePerPageOptions } from '@/constants/selectOptions'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import roleStoreModule from './roleStoreModule'
import RoleDetail from './detail.vue'
import CreateRole from './create.vue'
import useRoleHandler from './useRoleHandle'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    BOverlay,
    RoleDetail,
    BTabs,
    BTab,
    BModal,
    CreateRole,
    BRow,
    BCol,
    // BFormGroup,
    // BBadge,
    // vSelect,
  },

  setup() {
    const ROLE_APP_STORE_MODULE_NAME = 'app-roles'
    const toast = useToast()
    const roleActive = ref(0)
    const dataPermission = ref([])

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    }

    const {
      tableColumns,
      totalRecords,
      dataTable,
      fetchDataClientById,
      fetchDataRoles,
      searchTextFilter,
      loading,
      dataMeta,
      sizePerPage,
      currentPage,
      dataResource,
      clientId,
      dataClients,
      loadingRole,
      fetchClients,
      dataSearchText,
    } = useRoleHandler()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
      }
    })

    const createdByOptions = ref([])
    const isLoading = ref(false)
    const createdByOptionsDefault = ref([])

    function getPermissionInRole(clientId, name) {
      const wraper = {
        roleName: name,
        clientId,
      }
      store
        .dispatch('app-roles/getPermission', wraper)
        .then(e => {
          if (e.status === 200) {
            dataPermission.value = e.data.items
          }
        })
        .catch(err => {
          console.error(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Internal serve',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function insertRole(payload) {
      store
        .dispatch('app-roles/createRole', payload)
        .then(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `Added ${e.name} success`,
            },
          })
          this.$bvModal.hide('my-modal')
          fetchDataRoles()
        })
        .catch(err => {
          console.error(err)
          if (err.response && err.response.status === 409) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching customer list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Duplicate role',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching customer list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Internal serve',
              },
            })
          }
        })
    }

    function confirmDelete(roleName) {
      // show modal confirm delete employee
      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: this.$t('modal.confirmDelete') },
      })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.delRole(roleName)
          }
        })
    }

    function setPermissionInRole(payload, value) {
      const wraper = {
        roleName: value.name,
        clientId: clientId.value,
        payload,
      }
      store
        .dispatch('app-roles/setPermission', wraper)
        .then(() => {
          store.dispatch('userStore/fetchGrantedScopes')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Set permission success',
            },
          })
        })
        .catch(err => {
          console.error(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Internal serve',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function delRole(roleName = '') {
      store
        .dispatch('app-roles/deleteRole', roleName)
        .then(() => {
          store.dispatch('app-roles/getCustomerRoles')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Del permission success',
            },
          })
        })
        .catch(err => {
          console.error(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Internal serve',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoading.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ types: ['BE', 'SE', 'ADM'], searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 500)

    fetchDataRoles()
    fetchClients()
    return {
      // hieuht define
      dataTable,
      fetchDataClientById,
      fetchDataRoles,
      totalRecords,
      tableColumns,
      sizePerPageOptions,
      searchTextFilter,
      loading,
      isLoading,
      createdByOptions,
      dataMeta,
      sizePerPage,
      currentPage,
      dataResource,
      setPermissionInRole,
      getPermissionInRole,
      clientId,
      delRole,
      roleActive,
      loadingRole,

      insertRole,
      dataSearchText,
      dataClients,
      confirmDelete,
      dataPermission,
      handleOpenCreatedBy,
      handleSearchCreatedBy,

      resolveEmployeeTypeVariant,
      resolveEmployeeType,
    }
  },

  watch: {
    dataClients: {
      handler(data) {
        if (data && data.length > 0) {
          this.fetchDataClientById(data[0].clientId)
          this.getPermissionInRole(data[0].id, this.dataSearchText.length ? this.dataSearchText[0]?.name : this.dataTable[0]?.name)
        }
      },
    },
  },

  methods: {
    showModal() {
      this.$bvModal.show('my-modal')
    },

    hideModal() {
      this.$bvModal.hide('my-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 90px;
  .vs__selected-options {
    flex-wrap: nowrap;
  }
}
</style>

    <style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
